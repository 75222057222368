<template>
  <el-dialog :visible="dialogVisible" :show-close="false" @open="open">
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>{{title}}</span>
      </div>
    </div>
    <el-form
      label-position="top"
      width="900px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="80px"
    >
      <!-- <el-divider content-position="left">区域选择</el-divider> -->
      <el-form-item label="企业名称" prop="workEnterpriseCode">
        <el-select
          remote
          reserve-keyword
          :remote-method="query"
          v-model="ruleForm.workEnterpriseCode"
          clearable
          filterable
          placeholder="请输入企业名称"
          style="margin-right:10px;width:100%"
        >
          <el-option v-for="item in eList" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <div style="display: flex;">
        <el-form-item label="企业区域:省" prop="province">
          <el-select
            v-model="ruleForm.province"
            filterable
            :placeholder="'请选择省'"
            style="margin-right:1%"
            @change="provinceChange"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.value"
              :label="item.name "
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业区域:市" prop="city">
          <el-select v-model="ruleForm.city" filterable :placeholder="'请选择市'" @change="inputChange">
            <el-option v-for="item in cityList" :key="item.id" :label="item.name " :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      <el-button @click="closeDialog('ruleForm')">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
// 省
export const areaProvinceList = api()('area.province.list.json');
// 市
export const areaProvincePid = api()('area.province.pid.json');
// 企业区域列表
export const workEnterpriseList = api()('work_enterprise.list.json');
// 添加企业区域
export const workEnterpriseAreaAdd = api()('work_enterprise_area.add.json');
// 编辑企业区域
export const workEnterpriseAreaEdit = api()('work_enterprise_area.edit.json');
// 企业区域详情
export const workEnterpriseAreaInfo = api()('work_enterprise_area.info.json');
export default {
  components: {

  },
  data () {
    return {
      ruleForm: {

        workEnterpriseCode: '',
        province: '',
        city: ''
      },
      rules: {
        workEnterpriseCode: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        province: [{ required: true, message: '请选择省', trigger: 'blur' }],
        city: [{ required: true, message: '请选择市', trigger: 'blur' }]
      },
      newCode: this.code,
      eList: [],
      enterpriseSelectList: [],
      provinceList: [],
      cityList: [],
      loading: false
    };
  },
  props: {
    dialogVisible: Boolean,
    title: String,
    code: String
  },
  created () {


  },
  methods: {
    // 省级联动市级
    provinceChange (val) {
      areaProvincePid({ pid: val.toString() }).then((res) => {

        this.ruleForm.city = ''
        this.cityList = res.map(it => ({
          name: it.name,
          id: it.id
        }))
      });

    },
    // 组件刚打开的时候
    open () {
      if (this.title === '编辑企业区域') {
        workEnterpriseAreaInfo({ code: this.code }).then((res) => {
          console.log(res);
          this.ruleForm.workEnterpriseCode = res.work_enterprise.code
          this.ruleForm.province = res.province
          this.provinceChange(this.ruleForm.province)
          this.ruleForm.city = res.city
        });
      }

      areaProvinceList().then((res) => {
        this.provinceList = res.map(it => ({
          name: it.name,
          value: it.id
        }))
      });
      this.query('')
    },
    inputChange (val) {
      console.log(val);
    },
    // 提交表单
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {

          if (this.title === '编辑企业区域') {
            const params = {
              code: this.code,
              ... this.ruleForm
            };
            workEnterpriseAreaEdit(params).then(() => {
              const loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              setTimeout(() => {
                loading.close();
                this.closeDialog(formName)
              }, 2000)

            });
          }
          else {
            const params = {
              ... this.ruleForm
            };
            workEnterpriseAreaAdd(params).then(() => {
              const loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              setTimeout(() => {
                loading.close();
                this.closeDialog(formName)
              }, 2000)
            });
          }


        } else {

          return false;
        }
      });
    },
    // 清空form表单
    resetForm (formName) {
      this.$refs[formName].resetFields();

    },
    // 企业名称筛选
    query (val) {
      console.log(val);
      const params = {
        keywords: val
      };
      workEnterpriseList(params).then(res => {
        this.eList = res.list
      });
    },
    // 取消按钮
    closeDialog (formName) {

      this.resetForm(formName)
      this.ruleForm.workEnterpriseCode = ''
      this.ruleForm.province = ''
      this.ruleForm.city = ''
      this.$emit('update:dialogVisible', false);
      this.$emit('func', this.dialogVisible);


    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
